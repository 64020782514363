import React from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import {useAllContext} from "./context/context";

const HeroModern = () => {
  const {
    allPages,
    query,
  } = useAllContext();
  return (
    <>
      {  allPages
          .filter((pages) => pages.title.toLowerCase().includes(query))
          .map((pages) => (
      <div className="container-fluid hero-modern">
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                    <div className="section-title-center text-center">
                      <h2 className="display-6">{pages.title}</h2>
                      <span className="mb-3">{pages.subtitle}</span>
                      <div className="section-divider divider-triangle"></div>
                    </div>
                  </div>
                  <div className="offer__item__content">
                    <p dangerouslySetInnerHTML={{__html: pages.about}}/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                  <img style={{width: "48%"}}
                       className="img-fluid"
                       src={pages.img}
                       alt="Siege-Basini (Hardcover)"
                  />
              </div>
            </div>
            <hr/>
            <div className="row align-items-center">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="order-process__item">
                  <span>1</span>
                  <div>
                    <h5>Missão</h5>
                    <p>
                      {pages.mission}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="order-process__item">
                  <span>2</span>
                  <div>
                    <h5>Valores</h5>
                    <p>
                      {pages.values}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="order-process__item">
                  <span>3</span>
                  <div>
                    <h5>Visão</h5>
                    <p>
                      {pages.vision}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          ))}
    </>
  );
};

export default HeroModern;
