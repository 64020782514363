import React, {useEffect} from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useForm } from "react-hook-form";
import Header from "../global/header";
import Swal from "sweetalert2";
import {Link, useNavigate} from "react-router-dom";
import Footer from "../global/footer";

const AddWord = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    try {
      await addDoc(collection(db, "words"), {
        ...data,
        timeStamp: serverTimestamp(),
      });
      Swal.fire({
        icon: "success",
        text: "Palavra do dia adicionado com sucesso",
      });
      navigate("/gerenciar-palavra");
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: { err },
      });
    }
    e.target.reset();
  };
  return (
    <>
      <Header headers="add-book" />
      <div className="add-book section-padding">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Link to="/gerenciar-palavra">
                <button className="button button__primary float-end mb-3" type="button">
                  <span>Listar palavras</span>
                </button>
              </Link>
              <div className="col-md-12 mb-4">
                <div className="add-book__input">
                  <label htmlFor="desc">Palavra do dia</label>
                  <textarea
                      {...register("desc", {required: true})}
                      id="desc"
                      rows="4"
                      placeholder="Palavra do día"
                  />
                  {errors.description && <p>A palavra do dia é obrigatória</p>}
                  <label htmlFor="author">Nome do autor</label>
                  <input
                      {...register("author", {required: true})}
                      id="author"
                      type="text"
                      placeholder="Nome do autor"
                  />
                  {errors.author && <p>O autor é obrigatório</p>}
                  <div className="text-center mt-4">
                    <button
                        type="submit"
                        className="button button__primary"
                    >
                      <span>Adicionar palavra</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AddWord;
