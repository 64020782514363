import React, { useEffect, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useForm } from "react-hook-form";
import Header from "../global/header";
import Swal from "sweetalert2";
import {Link, useNavigate} from "react-router-dom";
import Footer from "../global/footer";
import { ProgressBar } from "react-bootstrap";

const AddVideo = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [video, setVideo] = useState({});
  const [perc, setPerc] = useState(null);

  useEffect(() => {
    const uploadFile = (e) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            text: { error },
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setVideo((prev) => ({ ...prev, video: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    try {
      await addDoc(collection(db, "videos"), {
        ...data,
        ...video,
        timeStamp: serverTimestamp(),
      });
      Swal.fire({
        icon: "success",
        text: "Imagem adicionada com sucesso",
      });
      navigate("/gerenciar-video");
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: { err },
      });
    }
    e.target.reset();
  };
  return (
    <>
      <Header headers="add-book" />
      <div className="add-book section-padding">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Link to="/gerenciar-video">
                <button className="button button__primary float-end mb-3" type="button">
                  <span>Listar videos</span>
                </button>
              </Link>
              <div className="col-md-12 mb-4">
                <div className="add-book__input">
                  <div className="add-book__input--image">
                    <label htmlFor="file" className="mt-0 mb-2">
                      <input
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          id="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          style={{display: "none"}}
                      />
                      { file ? (
                          <video width="320" height="240" controls>
                            <source src={URL.createObjectURL(file)} type="video/mp4"/>
                          </video>
                      ): (
                          <img
                              className="img-fluid"
                              src="https://icon-library.com/images/video-image-icon/video-image-icon-17.jpg"
                              alt=""
                          />
                      )}
                    </label>
                    {perc !== null ? (
                        <ProgressBar now={Math.round(perc)} label={`${Math.round(perc)}%`}/>
                    ) : (
                        ""
                    )}
                  </div>
                  <label htmlFor="desc">Descrição</label>
                  <textarea
                      {...register("desc", {required: true})}
                      id="desc"
                      rows="4"
                      placeholder="Descrição do livro"
                  />
                  {errors.description && <p>A descrição é obrigatória</p>}
                  <div className="text-center mt-4">
                    <button
                        disabled={perc !== null && perc < 100}
                        type="submit"
                        className="button button__primary"
                    >
                      <span>Adicionar video</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AddVideo;
