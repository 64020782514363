import React, { useState } from "react";
import { useAllContext } from "./context/context";
import LoadingSpinner from "./loading-spinner";
import OpenMediaModal from "./open-media-modal";

const Images = () => {
  const [visible, setVisible] = useState(8);
  const showMoreBooks = () => {
    setVisible((pervValue) => pervValue + 4);
  };

  const {
    allImages,
    allVideos,
    query,
    myRef,
  } = useAllContext();

  return (
      <div id="books" className="books section-padding section-bg" ref={myRef}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <h2 className="display-6">Galeria</h2>
                <div className="section-divider divider-triangle"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {allImages.length === 0 ? (
                <LoadingSpinner className="mb-4 col-md-6 col-lg-3"/>
            ) : (
                allImages
                    .filter((image) => image.desc.toLowerCase().includes(query))
                    .slice(0, visible)
                    .map((image) => (
                        <div className="col-lg-3 col-md-6 mb-4" key={image.id}>
                          <div className="books__book">
                            <img
                                className="img-fluid"
                                src={image.img}
                                alt={image.desc}
                            />
                            <ul className="functional-icons">
                              <li>
                                <OpenMediaModal book={image}/>
                              </li>
                            </ul>
                            <div className="p-3">
                              <p className="books__book__bottom--subtitle">
                                 {image.desc}
                              </p>
                            </div>
                          </div>
                        </div>
                    ))
            )}
          </div>
          <div className="book-load-btn text-center mt-4 mb-4">
            {allImages.length <= visible || query !== "" ? (
                ""
            ) : (
                <button onClick={showMoreBooks} className="button button__primary">
                  <span>Carregue mais</span>
                </button>
            )}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <h2 className="display-6">Videos</h2>
                <div className="section-divider divider-triangle"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {allVideos.length === 0 ? (
                <LoadingSpinner className="mb-4 col-md-6 col-lg-3"/>
            ) : (
                allVideos
                    .filter((video) => video.desc.toLowerCase().includes(query))
                    .slice(0, visible)
                    .map((video) => (
                        <div className="col-lg-3 col-md-6 mb-4" key={video.id}>
                          <div className="books__book">
                            <video style={{width: "100%"}} controls>
                              <source src={video.video} type="video/mp4"/>
                            </video>
                            <div className="p-3">
                              <p className="books__book__bottom--subtitle">
                                 {video.desc}
                              </p>
                            </div>
                          </div>
                        </div>
                    ))
            )}
          </div>
          <div className="book-load-btn text-center mt-4">
            {allVideos.length <= visible || query !== "" ? (
                ""
            ) : (
                <button onClick={showMoreBooks} className="button button__primary">
                  <span>Carregue mais</span>
                </button>
            )}
          </div>
        </div>
      </div>
  );
};

export default Images;
