import { Link } from "react-router-dom";
import Header from "../global/header";
import { RiDeleteBin6Line, RiEditBoxLine } from "react-icons/ri";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Swal from "sweetalert2";
import Footer from "../global/footer";
import { useAllContext } from "../context/context";
import Pagination from "../pagination";
import React, { useState } from "react";

const ManageOffer = ({
  setCart,
  handleChange,
  price,
  handleRemove,
  setQuery,
}) => {
  const { allOffers } = useAllContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const indexOfLastBook = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastBook - postsPerPage;
  const currentOffers = allOffers.slice(indexOfFirstPost, indexOfLastBook);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteBook = async (id) => {
    try {
      Swal.fire({
        icon: "success",
        text: "Oferta excluído com sucesso",
      });
      await deleteDoc(doc(db, "offers", id));
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: { err },
      });
    }
  };

  return (
    <>
      <Header headers="manage-book" />
      <section className="section-padding manege-book">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive bs-scroll">
                <Link to="/adicionar-oferta">
                  <button className="button button__primary float-end mb-3" type="button">
                    <span>Adicionar oferta</span>
                  </button>
                </Link>
                <table className="table border">
                  <thead>
                    <tr>
                      <th>Imagem</th>
                      <th>Titulo da oferta</th>
                      <th>Subtitulo da oferta</th>
                      <th>Atualizar</th>
                      <th>Remover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentOffers.map((allOffer) => (
                        <tr key={allOffer.id}>
                          <td>
                            <img
                                className="img-fluid"
                                src={allOffer.img}
                                alt={allOffer.title}
                            />
                          </td>
                          <td>
                            <span>{allOffer.title}</span>
                          </td>
                          <td>
                            <span>{allOffer.subtitle}</span>
                          </td>
                          <td>
                            <Link className="icon" to={`/update-oferta/${allOffer.id}`}>
                              <RiEditBoxLine/>
                            </Link>
                          </td>
                          <td>
                          <span
                              className="icon"
                              onClick={() => deleteBook(allOffer.id)}
                          >
                            <RiDeleteBin6Line/>
                          </span>
                          </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={allOffers.length}
          paginate={paginate}
        />
      </section>
      <Footer />
    </>
  );
};

export default ManageOffer;
