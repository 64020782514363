import React, { useEffect, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useForm } from "react-hook-form";
import Header from "../global/header";
import Swal from "sweetalert2";
import {Link, useNavigate} from "react-router-dom";
import Footer from "../global/footer";
import { ProgressBar } from "react-bootstrap";

const AddPage = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [image, setImage] = useState({});
  const [perc, setPerc] = useState(null);

  useEffect(() => {
    const uploadFile = (e) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            text: { error },
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImage((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    try {
      await addDoc(collection(db, "pages"), {
        ...data,
        ...image,
        timeStamp: serverTimestamp(),
      });
      Swal.fire({
        icon: "success",
        text: "Página adicionada com sucesso",
      });
      navigate("/gerenciar-pagina");
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: { err },
      });
    }
    e.target.reset();
  };
  return (
    <>
      <Header headers="add-book" />
      <div className="add-book section-padding">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Link to="/gerenciar-paginas">
                <button className="button button__primary float-end mb-3" type="button">
                  <span>Listar pagínas</span>
                </button>
              </Link>
              <div className="col-md-6 mb-4">
                <div className="add-book__input">
                  <div className="add-book__input--image">
                    <label htmlFor="file" className="mt-0 mb-2">
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        style={{ display: "none" }}
                      />
                      <img
                        className="img-fluid"
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                        }
                        alt=""
                      />
                    </label>
                    {perc !== null ? (
                      <ProgressBar now={Math.round(perc)} label={`${Math.round(perc)}%`} />
                    ) : (
                      ""
                    )}
                  </div>
                  <label htmlFor="title">Título do app</label>
                  <input
                    {...register("title", { required: true })}
                    id="title"
                    type="text"
                    placeholder="Título do app"
                  />
                  {errors.title && <p>O título é obrigatório</p>}
                  <label htmlFor="subtitle">Subtítulo do app</label>
                  <input
                    {...register("subtitle", { required: true })}
                    id="subtitle"
                    type="text"
                    placeholder="Subtítulo do app"
                  />
                  {errors.subtitle && <p>O subtítulo é obrigatória</p>}
                  <label htmlFor="about">Sobre</label>
                  <textarea
                      {...register("about", { required: true })}
                      id="about"
                      rows="4"
                      placeholder="Sobre"
                  />
                  {errors.about && <p>O sobre é obrigatória</p>}
                  <label htmlFor="cellphone">Telefone do app</label>
                  <input
                      {...register("cellphone", { required: true })}
                      id="cellphone"
                      type="text"
                      placeholder="Telefone do app"
                  />
                  {errors.cellphone && <p>O telefone é obrigatória</p>}
                  <label htmlFor="mission">Missão</label>
                  <input
                      {...register("mission", { required: true })}
                      id="mission"
                      type="text"
                      placeholder="Missão"
                  />
                  {errors.mission && <p>A Missão é obrigatória</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="add-book__input">
                  <label htmlFor="values">Valores</label>
                  <input
                      {...register("values", { required: true })}
                      id="values"
                      type="text"
                      placeholder="Valores"
                  />
                  {errors.values && <p>O valores é obrigatória</p>}
                  <label htmlFor="vision">Visão</label>
                  <input
                      {...register("vision", { required: true })}
                      id="vision"
                      type="text"
                      placeholder="Visão"
                  />
                  {errors.vision && <p>A visão é obrigatória</p>}
                  <label htmlFor="facebook">Facebook</label>
                  <input
                      {...register("facebook", {required: false})}
                      id="facebook"
                      type="text"
                      placeholder="Url"
                  />
                  <label htmlFor="x">X</label>
                  <input
                      {...register("x", {required: false})}
                      id="x"
                      type="text"
                      placeholder="Url"
                  />
                  <label htmlFor="instagram">Instagram</label>
                  <input
                      {...register("instagram", {required: false})}
                      id="instagram"
                      type="text"
                      placeholder="Url"
                  />
                  <label htmlFor="linkedIn">LinkedIn</label>
                  <input
                      {...register("linkedin", {required: false})}
                      id="linkedIn"
                      type="text"
                      placeholder="Url"
                  />
                  <label htmlFor="youtube">Youtube</label>
                  <input
                      {...register("youtube", {required: false})}
                      id="youtube"
                      type="text"
                      placeholder="Url"
                  />
                  <label htmlFor="tiktok">TikTok</label>
                  <input
                      {...register("tiktok", {required: false})}
                      id="tiktok"
                      type="text"
                      placeholder="Url"
                  />
                  <label htmlFor="whatsapp">Whatsapp</label>
                  <input
                      {...register("whatsapp", {required: false})}
                      id="whatsapp"
                      type="text"
                      placeholder="Url"
                  />
                  <div className="text-center mt-4">
                    <button
                        disabled={perc !== null && perc < 100}
                        type="submit"
                        className="button button__primary"
                    >
                      <span>Adicionar págína</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AddPage;
