import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {AiOutlineClose} from "react-icons/ai";
import { MdZoomOutMap } from "react-icons/md";
import { useAllContext } from "./context/context";

const OpenMediaModal = ({ book }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let date = new Date(book.publishedDate);
  const { cart } = useAllContext();

  return (
    <>
      <div className="icon" onClick={handleShow}>
        <MdZoomOutMap />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal fade signInModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal__wrapper">
          <div className="modal__wrapper--top">
            <h3>{book.title}</h3>
            <span className="close" onClick={handleClose}>
              <AiOutlineClose />
            </span>
          </div>
          <div className="row modal__wrapper__bottom">
            <div className="col-lg-12">
              <img style={{height:"auto" }} className="img-fluid" src={book.img} alt={book.title} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OpenMediaModal;
