import React from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useForm } from "react-hook-form";
import Header from "../global/header";
import Swal from "sweetalert2";
import {Link, useNavigate} from "react-router-dom";
import Footer from "../global/footer";

const AddFooter = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    try {
      await addDoc(collection(db, "footers"), {
        ...data,
        timeStamp: serverTimestamp(),
      });
      Swal.fire({
        icon: "success",
        text: "Rodapé adicionado com sucesso",
      });
      navigate("/gerenciar-rodape");
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: { err },
      });
    }
    e.target.reset();
  };
  return (
    <>
      <Header headers="add-book" />
      <div className="add-book section-padding">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Link to="/gerenciar-rodape">
                <button className="button button__primary float-end mb-3" type="button">
                  <span>Listar rodapés</span>
                </button>
              </Link>
              <div className="col-md-12 mb-4">
                <div className="add-book__input">
                  <label htmlFor="icon">Icon: <span><a target="_blank"
                                                       href="https://reactsvgicons.com/" rel="noreferrer">icones</a></span></label>
                  <input
                      {...register("icon", {required: true})}
                      id="icon"
                      type="text"
                      placeholder="icon"
                  />
                  {errors.icon && <p>O icon é obrigatório</p>}
                  <label htmlFor="title">Título do rodapé</label>
                  <input
                      {...register("title", { required: true })}
                      id="title"
                      type="text"
                      placeholder="Título do rodapé"
                  />
                  {errors.title && <p>O título do rodapé é obrigatório</p>}
                  <label htmlFor="desc">Descrição</label>
                  <textarea
                      {...register("desc", {required: true})}
                      id="desc"
                      rows="4"
                      placeholder="Descrição"
                  />
                  {errors.desc && <p>A descrição é obrigatória</p>}
                  <div className="text-center mt-4">
                    <button
                        type="submit"
                        className="button button__primary"
                    >
                      <span>Adicionar rodapé</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AddFooter;
