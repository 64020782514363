import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import About from "./component/pages/about";
import AddBook from "./component/pages/add-book";
import Login from "./component/pages/login";
import ManageBooks from "./component/pages/manage-book";
import Update from "./component/pages/update";
import BookshelfMinimal from "./component/pages/bookshelf-minimal";
import BookshelfClassic from "./component/pages/bookshelf-classic";
import AllBooks from "./component/pages/all-books";
import { useEffect } from "react";
import AddWord from "./component/pages/add-word";
import ManageWords from "./component/pages/manage-word";
import UpdateWord from "./component/pages/update-word";
import ManageImage from "./component/pages/manage-image";
import AddImage from "./component/pages/add-image";
import UpdateImage from "./component/pages/update-image";
import UpdateVideo from "./component/pages/update-video";
import AddVideo from "./component/pages/add-video";
import ManageVideo from "./component/pages/manage-video";
import AddBanner from "./component/pages/add-banner";
import ManageBanner from "./component/pages/manage-banner";
import UpdateBanner from "./component/pages/update-banner";
import AddOffer from "./component/pages/add-offer";
import ManageOffer from "./component/pages/manage-offer";
import UpdateOffer from "./component/pages/update-offer";
import AddFooter from "./component/pages/add-footer";
import ManageFooter from "./component/pages/manage-footer";
import UpdateFooter from "./component/pages/update-footer";
import AddPage from "./component/pages/add-page";
import ManagePages from "./component/pages/manage-page";
import UpdatePage from "./component/pages/update-page";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<BookshelfMinimal />} />
          <Route path="/sobre" element={<About />} />
          {/*<Route path="/salve-se-classico" element={<BookshelfClassic />} />*/}
          <Route path="/adicionar-livro" element={<AddBook />} />
          <Route path="/adicionar-palavra" element={<AddWord />} />
          <Route path="/adicionar-imagem" element={<AddImage />} />
          <Route path="/adicionar-video" element={<AddVideo />} />
          <Route path="/adicionar-banner" element={<AddBanner />} />
          <Route path="/adicionar-oferta" element={<AddOffer />} />
          <Route path="/adicionar-rodape" element={<AddFooter />} />
          <Route path="/adicionar-pagina" element={<AddPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/gerenciar-livro" element={<ManageBooks />} />
          <Route path="/gerenciar-palavra" element={<ManageWords />} />
          <Route path="/gerenciar-imagem" element={<ManageImage />} />
          <Route path="/gerenciar-video" element={<ManageVideo />} />
          <Route path="/gerenciar-banner" element={<ManageBanner />} />
          <Route path="/gerenciar-oferta" element={<ManageOffer />} />
          <Route path="/gerenciar-rodape" element={<ManageFooter />} />
          <Route path="/gerenciar-pagina" element={<ManagePages />} />
          <Route path="/update/:id" element={<Update />} />
          <Route path="/update-word/:id" element={<UpdateWord />} />
          <Route path="/update-image/:id" element={<UpdateImage />} />
          <Route path="/update-video/:id" element={<UpdateVideo />} />
          <Route path="/update-banner/:id" element={<UpdateBanner />} />
          <Route path="/update-oferta/:id" element={<UpdateOffer />} />
          <Route path="/update-rodape/:id" element={<UpdateFooter />} />
          <Route path="/update-pagina/:id" element={<UpdatePage />} />
          <Route path="/todos-livros" element={<AllBooks />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
