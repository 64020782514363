/* eslint-disable react-hooks/exhaustive-deps */
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../global/header";
import Footer from "../global/footer";
import { useAllContext } from "../context/context";
import ProgressBar from "react-bootstrap/ProgressBar";

const Update = () => {
  const [data, setData] = useState([]);
  const [file, setFile] = useState("");
  const [perc, setPerc] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const { allPages } = useAllContext();
  const singleData = allPages.filter((data) => data.id === id);
  const updateData = singleData[0];

  useEffect(() => {
    if (data) {
      setData({
        img: updateData?.img,
        title: updateData?.title,
        subtitle: updateData?.subtitle,
        about: updateData?.about,
        cellphone: updateData?.cellphone,
        mission: updateData?.mission,
        values: updateData?.values,
        vision: updateData?.vision,
        facebook: updateData?.facebook,
        x: updateData?.x,
        instagram: updateData?.instagram,
        linkedin: updateData?.linkedin,
        youtube: updateData?.youtube,
        tiktok: updateData?.tiktok,
        whatsapp: updateData?.whatsapp,
      });
    }
    const uploadFile = (e) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            text: { error },
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData({ ...data, img: downloadURL });
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);
  const updateBook = (id) => {
    const timer = setTimeout(() => {
      const bookDoc = doc(db, "pages", id);
      const newBook = data;
      updateDoc(bookDoc, newBook);
      Swal.fire({
        icon: "success",
        text: "Página atualizada com sucesso",
      });
      navigate("/gerenciar-pagina");
    }, 100);
    return () => clearTimeout(timer);
  };
  return (
    <>
        <Header headers="manage-book" />
        <div className="add-book section-padding">
        <div className="container">
          <form>
            <div className="row">
              <Link to="/gerenciar-pagina">
                  <button className="button button__primary float-end mb-3" type="button">
                      <span>Listar páginas</span>
                  </button>
              </Link>
              <div className="col-md-6 mb-4">
                  <div className="add-book__input">
                      <div className="add-book__input--image">
                          <label htmlFor="file" className="mt-0 mb-2">
                              <input
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  id="file"
                                  onChange={(e) => setFile(e.target.files[0])}
                                  style={{display: "none"}}
                              />
                              <img
                                  className="img-fluid"
                                  src={file ? URL.createObjectURL(file) : updateData?.img}
                                  alt=""
                              />
                          </label>
                          {perc !== null ? (
                              <ProgressBar
                                  now={Math.round(perc)}
                                  label={`${Math.round(perc)}%`}
                              />
                          ) : (
                              ""
                          )}
                      </div>
                      <label htmlFor="title">Título do app</label>
                      <input
                          id="title"
                          type="text"
                          placeholder="Título do app"
                          defaultValue={updateData?.title}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  title: e.target.value,
                              })
                          }
                      />
                      <label htmlFor="subtitle">ubtítulo do app</label>
                      <input
                          id="subtitle"
                          type="text"
                          placeholder="ubtítulo do app"
                          defaultValue={updateData?.subtitle}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  subtitle: e.target.value,
                              })
                          }
                      />
                      <label htmlFor="about">Sobre</label>
                      <textarea
                          id="about"
                          rows="4"
                          placeholder="Sobre"
                          defaultValue={updateData?.about}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  about: e.target.value,
                              })
                          }
                      />
                      <label htmlFor="cellphone">Telefone do app</label>
                      <input
                          id="cellphone"
                          type="text"
                          placeholder="Telefone do app"
                          defaultValue={updateData?.cellphone}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  cellphone: e.target.value,
                              })
                          }
                      />
                      <label htmlFor="mission">Missão</label>
                      <input
                          id="mission"
                          type="text"
                          placeholder="Missão"
                          defaultValue={updateData?.mission}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  mission: e.target.value,
                              })
                          }
                      />
                  </div>
              </div>
                <div className="col-md-6">
                    <div className="add-book__input">
                        <label htmlFor="values">Valores</label>
                        <input
                            id="values"
                            type="text"
                            placeholder="Valores"
                            defaultValue={updateData?.values}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    values: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="vision">Visão</label>
                        <input
                            id="vision"
                            type="text"
                            placeholder="Visão"
                            defaultValue={updateData?.vision}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    vision: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="facebook">Facebook</label>
                        <input
                            id="facebook"
                            type="text"
                            placeholder="Url"
                            defaultValue={updateData?.facebook}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    facebook: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="x">X</label>
                        <input
                            id="x"
                            type="text"
                            placeholder="Url"
                            defaultValue={updateData?.x}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    x: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="instagram">Instagram</label>
                        <input
                            id="instagram"
                            type="text"
                            placeholder="Url"
                            defaultValue={updateData?.instagram}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    instagram: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="linkedIn">LinkedIn</label>
                        <input
                            id="linkedIn"
                            type="text"
                            placeholder="Url"
                            defaultValue={updateData?.linkedin}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    linkedin: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="youtube">Youtube</label>
                        <input
                            id="youtube"
                            type="text"
                            placeholder="Url"
                            defaultValue={updateData?.youtube}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    youtube: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="tiktok">TikTok</label>
                        <input
                            id="tiktok"
                            type="text"
                            placeholder="Url"
                            defaultValue={updateData?.tiktok}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    tiktok: e.target.value,
                                })
                            }
                        />
                        <label htmlFor="whatsapp">Whatsapp</label>
                        <input
                            id="whatsapp"
                            type="text"
                            placeholder="Url"
                            defaultValue={updateData?.whatsapp}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    whatsapp: e.target.value,
                                })
                            }
                        />
                        <div className="text-center mt-4">
                            <button
                                className="button button__primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    updateBook(updateData?.id);
                                }}
                                disabled={perc !== null && perc < 100}
                            >
                                <span>Atualizar página</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
          </form>
        </div>
        </div>
        <Footer/>
    </>
  );
};

export default Update;
