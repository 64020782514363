/* eslint-disable react-hooks/exhaustive-deps */
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../global/header";
import Footer from "../global/footer";
import { useAllContext } from "../context/context";
import ProgressBar from "react-bootstrap/ProgressBar";

const Update = () => {
  const [data, setData] = useState([]);
  const [file, setFile] = useState("");
  const [perc, setPerc] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const { allBanners } = useAllContext();
  const singleData = allBanners.filter((data) => data.id === id);
  const updateData = singleData[0];

  useEffect(() => {
    if (data) {
      setData({
        img: updateData?.img,
        title: updateData?.title,
        subtitle: updateData?.subtitle,
        desc: updateData?.desc,
        author: updateData?.author,
      });
    }
    const uploadFile = (e) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            text: { error },
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData({ ...data, img: downloadURL });
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);
  const updateBook = (id) => {
    const timer = setTimeout(() => {
      const bookDoc = doc(db, "banners", id);
      const newBook = data;
      updateDoc(bookDoc, newBook);
      Swal.fire({
        icon: "success",
        text: "Banner atualizado com sucesso",
      });
      navigate("/gerenciar-banner");
    }, 100);
    return () => clearTimeout(timer);
  };
  return (
    <>
        <Header headers="manage-book" />
        <div className="add-book section-padding">
        <div className="container">
          <form>
            <div className="row">
              <Link to="/gerenciar-banner">
                  <button className="button button__primary float-end mb-3" type="button">
                      <span>Listar Banners</span>
                  </button>
              </Link>
              <div className="col-md-12 mb-4">
                  <div className="add-book__input">
                      <div className="add-book__input--image">
                          <label htmlFor="file" className="mt-0 mb-2">
                              <input
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  id="file"
                                  onChange={(e) => setFile(e.target.files[0])}
                                  style={{display: "none"}}
                              />
                              <img
                                  className="img-fluid"
                                  src={file ? URL.createObjectURL(file) : updateData?.img}
                                  alt=""
                              />
                          </label>
                          {perc !== null ? (
                              <ProgressBar
                                  now={Math.round(perc)}
                                  label={`${Math.round(perc)}%`}
                              />
                          ) : (
                              ""
                          )}
                      </div>
                      <label htmlFor="title">Título do banner</label>
                      <input
                          id="title"
                          type="text"
                          placeholder="Título do livro"
                          defaultValue={updateData?.title}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  title: e.target.value,
                              })
                          }
                      />
                      <label htmlFor="subtitle">Subtítulo do banner</label>
                      <input
                          id="subtitle"
                          type="text"
                          placeholder="Subtítulo do livro"
                          defaultValue={updateData?.subtitle}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  subtitle: e.target.value,
                              })
                          }
                      />
                      <label htmlFor="desc">Descrição do banner</label>
                      <textarea
                          id="desc"
                          rows="4"
                          placeholder="Descrição do livro"
                          defaultValue={updateData?.desc}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  desc: e.target.value,
                              })
                          }
                      />
                      <label htmlFor="author">Nome do autor</label>
                      <input
                          id="author"
                          type="text"
                          placeholder="Nome do autor"
                          defaultValue={updateData?.author}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  author: e.target.value,
                              })
                          }
                      />
                      <div className="text-center mt-4">
                          <button
                              className="button button__primary"
                              onClick={(e) => {
                                  e.preventDefault();
                                  updateBook(updateData?.id);
                              }}
                              disabled={perc !== null && perc < 100}
                          >
                              <span>Atualizar banner</span>
                          </button>
                      </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
        </div>
        <Footer/>
    </>
  );
};

export default Update;
