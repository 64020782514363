/* eslint-disable react-hooks/exhaustive-deps */
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../global/header";
import Footer from "../global/footer";
import { useAllContext } from "../context/context";
import ProgressBar from "react-bootstrap/ProgressBar";

const Update = () => {
  const [data, setData] = useState([]);
  const [file, setFile] = useState("");
  const [perc, setPerc] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const { allVideos } = useAllContext();
  const singleData = allVideos.filter((data) => data.id === id);
  const updateData = singleData[0];

  useEffect(() => {
    if (data) {
      setData({
        video: updateData?.video,
        desc: updateData?.desc,
      });
    }
    const uploadFile = (e) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            text: { error },
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData({ ...data, video: downloadURL });
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);
  const updateBook = (id) => {
    const timer = setTimeout(() => {
      const bookDoc = doc(db, "videos", id);
      const newBook = data;
      updateDoc(bookDoc, newBook);
      Swal.fire({
        icon: "success",
        text: "Video atualizada com sucesso",
      });
      navigate("/gerenciar-video");
    }, 100);
    return () => clearTimeout(timer);
  };
  return (
    <>
        <Header headers="manage-book" />
        <div className="add-book section-padding">
        <div className="container">
          <form>
            <div className="row">
              <Link to="/gerenciar-video">
                  <button className="button button__primary float-end mb-3" type="button">
                      <span>Listar videos</span>
                  </button>
              </Link>
              <div className="col-md-12 mb-4">
                  <div className="add-book__input">
                      <div className="add-book__input--image">
                          <label htmlFor="file" className="mt-0 mb-2">
                              <input
                                  type="file"
                                  accept="video/mp4,video/x-m4v,video/*"
                                  id="file"
                                  onChange={(e) => setFile(e.target.files[0])}
                                  style={{display: "none"}}
                              />
                              { file ? (
                                  <video width="320" height="240" controls>
                                      <source src={URL.createObjectURL(file)} type="video/mp4"/>
                                  </video>
                              ): (
                                  <video
                                      width="320" height="240"
                                      src={updateData?.video}
                                      alt=""
                                  />
                              )}
                          </label>
                          {perc !== null ? (
                              <ProgressBar
                                  now={Math.round(perc)}
                                  label={`${Math.round(perc)}%`}
                              />
                          ) : (
                              ""
                          )}
                      </div>
                      <label htmlFor="desc">Descrição do video</label>
                      <textarea
                          id="desc"
                          rows="4"
                          placeholder="Descrição da imagem"
                          defaultValue={updateData?.desc}
                          onChange={(e) =>
                              setData({
                                  ...data,
                                  desc: e.target.value,
                              })
                          }
                      />
                      <div className="text-center mt-4">
                          <button
                              className="button button__primary"
                              onClick={(e) => {
                                  e.preventDefault();
                                  updateBook(updateData?.id);
                              }}
                              disabled={perc !== null && perc < 100}
                          >
                              <span>Atualizar video</span>
                          </button>
                      </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
        </div>
        <Footer/>
    </>
  );
};

export default Update;
