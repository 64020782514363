import React from "react";
import {Link} from "react-router-dom";
import { useAllContext } from "./context/context";
import LoadingSpinner from "./loading-spinner";

const Offer = () => {
  const {
    allOffers,
    query,
  } = useAllContext();
  return (
    <section className="offer section-padding">
      <div className="container">
        <div className="row">
          {allOffers.length === 0 ? (
              <LoadingSpinner className="mb-4 col-md-6 col-lg-3"/>
          ) : (
              allOffers
                  .filter((offer) => offer.title.toLowerCase().includes(query))
                  .map((offer) => (
                    <div className="col-lg-6 col-sm-6 mb-4 mb-sm-0" key={offer.id}>
                      <div className="offer__item h-100">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="offer__item__image">
                              <img
                                  className="img-fluid"
                                  src={offer.img}
                                  alt={offer.title}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="offer__item__content">
                              <h3>{offer.title}</h3>
                              <span className="badge-text">{offer.subtitle}</span>
                              <p>{offer.desc}</p>
                              <Link to="/todos-livros" className="button button__primary">
                                <span>Compre agora</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Offer;
