import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import {Link, useNavigate} from "react-router-dom";
import { auth } from "../../firebase";
import {useAllContext} from "../context/context";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        navigate("/");
      })
      .catch((err) => {
        setError(err.message);
      });
  };
  const {
    allPages,
    query,
  } = useAllContext();

  return (
    <>
      {  allPages
          .filter((pages) => pages.title.toLowerCase().includes(query))
          .map((pages) => (
                <div className="login text-center" style={{backgroundColor: "#5A2B3B"}} key={pages.id}>
                    <form onSubmit={handleLogin} action="">
                        <Link to="/">
                          <img className="mb-5" src={pages.img} alt={pages.title}/>
                        </Link>
                        <input
                            type="email"
                            placeholder="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="senha"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className="button button__primary" type="submit">
                          <span>Login</span>
                        </button>
                        <Link to="/">
                          <button className="button button__primary bg-warning" type="button">
                            <span>voltar</span>
                          </button>
                        </Link>
                        {error && <span className="error">E-mail ou senha incorretos!</span>}
                      </form>
                </div>
          ))}
    </>
  );
};

export default Login;
