import React, { useEffect, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useForm } from "react-hook-form";
import Header from "../global/header";
import Swal from "sweetalert2";
import {Link, useNavigate} from "react-router-dom";
import Footer from "../global/footer";
import { ProgressBar } from "react-bootstrap";

const AddOffer = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [image, setImage] = useState({});
  const [perc, setPerc] = useState(null);

  useEffect(() => {
    const uploadFile = (e) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            text: { error },
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImage((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    try {
      await addDoc(collection(db, "offers"), {
        ...data,
        ...image,
        timeStamp: serverTimestamp(),
      });
      Swal.fire({
        icon: "success",
        text: "Oferta adicionada com sucesso",
      });
      navigate("/gerenciar-oferta");
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: { err },
      });
    }
    e.target.reset();
  };
  return (
    <>
      <Header headers="add-book" />
      <div className="add-book section-padding">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Link to="/gerenciar-oferta">
                <button className="button button__primary float-end mb-3" type="button">
                  <span>Listar ofertas</span>
                </button>
              </Link>
              <div className="col-md-12 mb-4">
                <div className="add-book__input">
                  <div className="add-book__input--image">
                    <label htmlFor="file" className="mt-0 mb-2">
                      <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          id="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          style={{display: "none"}}
                      />
                      <img
                          className="img-fluid"
                          src={
                            file
                                ? URL.createObjectURL(file)
                                : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                          }
                          alt=""
                      />
                    </label>
                    {perc !== null ? (
                        <ProgressBar now={Math.round(perc)} label={`${Math.round(perc)}%`}/>
                    ) : (
                        ""
                    )}
                  </div>
                  <label htmlFor="title">Título da oferta</label>
                  <input
                      {...register("title", {required: true})}
                      id="title"
                      type="text"
                      placeholder="Título da oferta"
                  />
                  {errors.title && <p>O título é obrigatório</p>}
                  <label htmlFor="subtitle">Subtítulo da oferta</label>
                  <input
                      {...register("subtitle", {required: true})}
                      id="subtitle"
                      type="text"
                      placeholder="Subtítulo da oferta"
                  />
                  {errors.subtitle && <p>O subtítulo é obrigatória</p>}
                  <label htmlFor="desc">Descrição da oferta</label>
                  <textarea
                      {...register("desc", {required: true})}
                      id="desc"
                      rows="4"
                      placeholder="Descrição da oferta"
                  />
                  {errors.desc && <p>A descrição é obrigatória</p>}
                  <div className="text-center mt-4">
                    <button
                        disabled={perc !== null && perc < 100}
                        type="submit"
                        className="button button__primary"
                    >
                      <span>Adicionar oferta</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AddOffer;
