import Footer from "../global/footer";
import Header from "../global/header";
import HeroModern from "../hero-modern";
import Images from "../images";

const About = () => {
  return (
    <>
      <Header headers="modern" />
      <HeroModern />
      <Images/>
      <Footer />
    </>
  );
};

export default About;
